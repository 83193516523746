<template>
  <div class="info-unit">
    <p>{{ titleKey }}：</p>
    <div class="info-unit-right">
      <p>{{ titleValue }}</p>
      <ul class="info-unit-right-value">
        <li v-for="(item, index) of infoArray" :key="index">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import infoUnitTool from "./info-unit-tool.js";

export default {
  props: {
    titleKey: String,
    titleValue: String,
  },

  computed: {
    infoArray() {
      switch (this.titleKey) {
        case "儿童报告诊断标准":
          return infoUnitTool.standardContentArray(this.titleValue);
        case "报告评估建议":
          return infoUnitTool.suggestionContentArray(this.titleValue);
      }
    },
  },

  methods: {},
};
</script>


<style lang="scss" scoped>

.info-unit {
  color: black;
  font-size: rem(14);
  line-height: rem(20);
  display: flex;

  &-right {
    &-value {
      color: #666666;
      margin-top: rem(29);
      display: flex;
      flex-direction: column;
    }
  }
}
</style>