<template>
  <div class="icon-unit">
    <p class="icon-unit-title" v-if="iconType != 'noTitle'">{{ titleKey }}：{{ titleValue }}</p>
    <div v-if="titleKey == '机构睡眠报告抬头名称'" class="icon-unit-subtitle">
      <p  class="icon-unit-subtitle-title">{{ subtitleKey }}：</p>
      <p class="icon-unit-subtitle-value">{{ subtitleValue }}</p>
    </div>
    
    <div class="icon-unit-bottom">
      <p class="icon-unit-bottom-key">{{ iconKey }}：</p>
      <img class="icon-unit-bottom-icon" :src="iconURL" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subtitleKey:String,
    subtitleValue:String,
    titleKey: String,
    titleValue: String,
    iconKey: String,
    iconURL: String,
    iconType:String,
  },
};
</script>


<style lang="scss" scoped>

.icon-unit {
  color: black;
  font-size: rem(14);

  &-title {
    height: rem(20);
  }

  &-subtitle {
    display: flex;
    margin-top: rem(18);
    height: rem(20);
    &-value{
      margin-left: rem(14);
    }
  }
  &-bottom {
    margin-top: rem(18);
    display: flex;

    &-key {
      height: rem(20);
    }

    &-icon {
      width: rem(70);
      height: rem(70);
    }
  }
}
</style>