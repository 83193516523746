<template>
    <div class="display">
        <div class="display-top">
            <div class="display-top-left">
                <p class="display-top-left-title">基本配置</p>
                <icon-unit class="display-top-left-icon" titleKey="机构睡眠报告抬头名称" subtitleKey="机构睡眠报告副标题"
                    :titleValue="reportTitle" :subtitleValue="reportSubtitle" iconKey="睡眠报告Logo"
                    :iconURL="reportLogo"></icon-unit>
            </div>
            <div class="display-top-right">
                <div class="display-top-right-trend">
                    <p class="display-top-right-trend-title">趋势图显示配置</p>
                    <div class="display-top-right-trend-blood-oxygen">
                        <p class="display-top-right-trend-blood-oxygen-title">血氧/脉率聚合显示：</p>
                        <el-switch class="display-top-right-trend-blood-oxygen-switch"
                            v-model="isCombineBloodOxygenAndPulse" active-color="#6DA4D8" inactive-color="#DCDFE6"
                            disabled>
                        </el-switch>
                    </div>
                    <div class="display-top-right-trend-installment">
                        <p class="display-top-right-trend-installment-title">
                            睡眠分期/呼吸事件聚合显示：
                        </p>
                        <el-switch class="display-top-right-trend-installment-switch" v-model="isCombinePeriodAndEvent"
                            active-color="#6DA4D8" inactive-color="#DCDFE6" disabled>
                        </el-switch>
                    </div>


                </div>
                <div class="display-top-right-invalid">
                    <p class="display-top-right-invalid-title">无效报告配置</p>
                    <div class="display-top-right-invalid-blood-oxygen">
                        <p class="display-top-right-invalid-blood-oxygen-title">开放缺少睡眠初筛报告查看：</p>
                        <el-switch class="display-top-right-invalid-blood-oxygen-switch"
                            v-model="isOxygenDeficiencyView" active-color="#6DA4D8" inactive-color="#DCDFE6" disabled>
                        </el-switch>
                    </div>
                </div>
            </div>


        </div>
        <div class="display-line"></div>
        <div class="display-bottom">
            <info-unit titleKey="儿童报告诊断标准" :titleValue="standardTitle"></info-unit>
            <info-unit class="display-bottom-right" titleKey="报告评估建议" :titleValue="suggestionTitle"></info-unit>
        </div>


    </div>
</template>

<script>
import IconUnit from "@c/setting/organization-configuration/display/icon-unit.vue";
import InfoUnit from "@c/setting/organization-configuration/display/info-unit/info-unit.vue";
export default {
    props: {
        model: Object
    },
    components: {
        IconUnit,
        InfoUnit,
    },
    data() {
        return {
            openOxygenDeficiencyViewReport: false,
            isCombineBloodOxygenAndPulse: false,
            isCombinePeriodAndEvent: false,
            isOxygenDeficiencyView: false,

        };
    },


    computed: {
   

        hospitalLogo() {
            if (this.model.hospitalLogo == undefined) {
                return require("@imgs/logo-placeholder.png");
            } else {
                return this.model.hospitalLogo;
            }
        },

        reportTitle() {
            return this.model.reportTitle;
        },
        reportSubtitle() {
            return this.model.reportSubtitle;
        },

        reportLogo() {
            if (this.model.reportLogo == undefined) {
                return require("@imgs/logo-placeholder.png");
            } else {
                return this.model.reportLogo;
            }
        },

        standardTitle() {
            return findKey(CHILD_STANDDARD_TYPE_CODE, this.model.reportStandard);
        },

        suggestionTitle() {
            return findKey(REPORT_SUGGESTION_TYPE, this.model.reportEvaluate);
        },
    },

    created() {
        this.initVulue()
    },

    methods: {
        // action

        initVulue() {
            if (this.model.bloodPulse == "1") {
                this.isCombineBloodOxygenAndPulse = true
            } else {
                this.isCombineBloodOxygenAndPulse = false
            }

            if (this.model.stageEvent == "1") {
                this.isCombinePeriodAndEvent = true
            } else {
                this.isCombinePeriodAndEvent = false
            }

            if (this.model.oxygenDeficiencyView == "1") {
                this.isOxygenDeficiencyView = true
            } else {
                this.isOxygenDeficiencyView = false
            }
        }

    },

    watch: {

        model(newValue) {
            if (newValue.bloodPulse == "1") {
                this.isCombineBloodOxygenAndPulse = true
            } else {
                this.isCombineBloodOxygenAndPulse = false
            }

            if (newValue.stageEvent == "1") {
                this.isCombinePeriodAndEvent = true
            } else {
                this.isCombinePeriodAndEvent = false
            }

            if (newValue.oxygenDeficiencyView == "1") {
                this.isOxygenDeficiencyView = true
            } else {
                this.isOxygenDeficiencyView = false
            }

        }
    },
};
</script>


<style lang="scss" scoped>
.display {
    background-color: white;
    width: 100%;
    position: relative;

    &-top {
        display: flex;

        &-right {
            position: absolute;
            left: rem(618);

            &-trend {
                &-title {
                    color: black;
                    font-size: rem(16);
                    font-weight: bold;
                    height: rem(22);
                    margin-top: rem(16);
                }

                &-blood-oxygen {
                    display: flex;
                    align-items: center;
                    height: rem(26);
                    margin-top: rem(15);
                    font-size: rem(14);
                    color: black;

                    &-title {
                        width: rem(199);
                    }

                    &-switch {
                        transform: scale(1.3);
                        margin-left: rem(6);

                    }
                }

                &-installment {
                    display: flex;
                    align-items: center;
                    height: rem(26);
                    margin-top: rem(15);
                    font-size: rem(14);
                    color: black;

                    &-title {
                        width: rem(199);
                    }

                    &-switch {
                        transform: scale(1.3);
                        margin-left: rem(6);

                    }
                }


            }

            &-invalid {
                &-title {
                    color: black;
                    font-size: rem(16);
                    font-weight: bold;
                    height: rem(22);
                    margin-top: rem(28);
                }

                &-blood-oxygen {
                    display: flex;
                    align-items: center;
                    height: rem(26);
                    margin-top: rem(15);
                    font-size: rem(14);
                    color: black;

                    &-title {
                        width: rem(199);
                    }

                    &-switch {
                        transform: scale(1.3);
                        margin-left: rem(6);

                    }
                }
            }
        }

        &-left {
            &-title {
                color: black;
                font-size: rem(16);
                font-weight: bold;
                height: rem(22);
                margin-top: rem(16);
            }

            &-icon {
                margin-top: rem(10);
            }
        }
    }

    &-line {
        background-color: #e8e8e8;
        width: 100%;
        height: rem(1);
        margin-top: rem(65);
    }

    &-bottom {
        margin-top: rem(35);
        display: flex;

        &-right {
            position: absolute;
            left: rem(618);
        }
    }

}
</style>